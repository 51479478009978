import React from "react";
import * as d3 from "d3";
import i18next from "i18next";

import { formatLocalAmount } from "../../utility/number_formatter";
import { DataPoint } from "../../models/result";
import { TooltipEvent } from "../../utility/analytics";

interface ITotalAreaProps {
    id: string;
    height: number;
    width: number;
    xScale?: d3.ScaleLinear<number, number>;
    yScale?: d3.ScaleLinear<number, number>;
    summary: DataPoint;
    totalAreaOnTop: boolean;
    onEngagement: (label: TooltipEvent) => void;
}

export class TotalArea extends React.Component<ITotalAreaProps> {
    areaRef = React.createRef<SVGPathElement>();
    private resizeFn: ReturnType<typeof setTimeout>;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.addAreaHoverListener();
    }

    componentDidUpdate() {
        this.closeAreaTooltip();
        clearTimeout(this.resizeFn);
        this.resizeFn = setTimeout(() => {
            this.addAreaHoverListener();
        }, 200);
    }

    addAreaHoverListener() {
        const { xScale, summary, totalAreaOnTop } = this.props;
        const tooltipCircles = document.querySelectorAll(".area-tooltip-circle");
        const summaryLine = document.querySelector(".summary-line");
        const setAreaTooltipCirclePos = this.setAreaTooltipCirclePos.bind(this);
        const setAreaTooltipModal = this.setAreaTooltipModal.bind(this);
        const closeAreaTooltip = this.closeAreaTooltip;
        const thickenSummaryLine = this.thickenSummaryLine;
        const unthickenSummaryLine = this.unthickenSummaryLine;
        const setTooltipCircleColor = this.setTooltipCircleColor;

        d3.select(this.areaRef.current)
            .on("mouseenter", () => this.props.onEngagement(TooltipEvent.AREA_TOOLTIP))
            .on("mousemove", (event) => {
                const year = Math.round(xScale.invert(d3.pointer(event)[0]));
                const yValue = Number.isFinite(summary.scenarioFuture[year]) ? summary.scenarioFuture[year] : summary.future[year];
                setAreaTooltipCirclePos(year, tooltipCircles, yValue);
                setAreaTooltipModal(year, tooltipCircles[0], yValue);
                if (totalAreaOnTop) {
                    setTooltipCircleColor(tooltipCircles, yValue);
                } else {
                    thickenSummaryLine(summaryLine);
                }
            })
            .on("mouseout", function () {
                closeAreaTooltip();
                if (!totalAreaOnTop) {
                    unthickenSummaryLine(summaryLine);
                }
            });
    }

    setAreaTooltipCirclePos(year, tooltipCircles, yValue) {
        const { xScale, yScale } = this.props;
        tooltipCircles.forEach((circle) => {
            circle.setAttribute("cx", xScale(year).toString());
            circle.setAttribute("cy", yScale(yValue).toString());
            circle.classList.add("active");
        });
    }

    setAreaTooltipModal(year, tooltipCircle, yValue) {
        const areaTooltip = document.querySelector(".area-tooltip-modal");
        this.setAreaTooltipModalContent(areaTooltip, year, yValue);
        this.setAreaTooltipModalPosition(areaTooltip, tooltipCircle, year);
    }

    setAreaTooltipModalContent(areaTooltip, year, yValue) {
        const { id } = this.props;
        const amount = formatLocalAmount(yValue);
        areaTooltip.childNodes[0].innerText = `${i18next.t(`dynamic:${id}-summary`) || id} ${
            year === 0 ? i18next.t("prognosis:today").toLowerCase() : i18next.t("prognosis:inyears", { year })
        }:`;
        areaTooltip.childNodes[1].innerText = `${amount} ${i18next.t(`dynamic:${id}-sek-unit`)}`;
    }

    setAreaTooltipModalPosition(areaTooltip, tooltipCircle, year) {
        const circlePos = tooltipCircle.getBoundingClientRect();
        const chartContainerPos = document.querySelector(".chart-container").getBoundingClientRect();
        const tooltipWidth = areaTooltip.getBoundingClientRect().width;
        const tooltipPosX = year < 5 ? circlePos.left - chartContainerPos.left : circlePos.left - chartContainerPos.left - tooltipWidth + 20;
        const tooltipPosY = circlePos.top - chartContainerPos.top + 25;
        areaTooltip.style.setProperty("left", `${tooltipPosX}px`, "important");
        areaTooltip.style.setProperty("top", `${tooltipPosY}px`, "important");
        areaTooltip.classList.add("active");
    }

    thickenSummaryLine(summaryLine) {
        summaryLine.style.setProperty("stroke-width", "8px", "important");
    }

    unthickenSummaryLine(summaryLine) {
        summaryLine.style.removeProperty("stroke-width");
    }

    setTooltipCircleColor(tooltipCircles, yValue) {
        if (yValue >= 0) {
            tooltipCircles[1].classList.remove("negative");
        } else {
            tooltipCircles[1].classList.add("negative");
        }
    }

    closeAreaTooltip() {
        const areaTooltip = document.querySelector(".area-tooltip-modal");
        areaTooltip.classList.remove("active");
        const tooltipCircles = document.querySelectorAll(".area-tooltip-circle");
        tooltipCircles.forEach((circle) => {
            circle.classList.remove("active");
        });
    }

    render() {
        const { width, height } = this.props;
        const coordinates = `M0,0L0,0L${width},0L${width},${height}L${width},${height}L0,${height}Z`;

        return <path className="total-area" d={coordinates} opacity={0} ref={this.areaRef}></path>;
    }
}
