import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React from "react";

import { ScenarioTemplate, IncompleteStateScenario, IScenarioProps } from "../scenarios";
import { IRootState, resetAmortizationScenario, setAmortizationRequirementScenario, setAmortizationScenario } from "../../reducers/rootReducer";
import NumberComponent from "../form_controls/number_component";
import { getAmortizationRatio, getMortgage } from "../../functions/household";
import CheckboxComponent from "../form_controls/checkbox_component";
import DisclaimerComponent from "../typography/disclaimer_component";
import ErrorTextComponent from "../typography/errortext_component";
import { minAmortizationRate } from "../../functions/installment";
import { householdIncomeForecastForYear } from "../../functions/income";
import { convertToPercent } from "../../functions/calculations";
import { getHousingPriceByCalculationType } from "../../selectors/household";
import { ScenarioEvent } from "../../utility/analytics";

const getAmortizationScenarioData = (state: IRootState) => {
    return state.scenarioData?.mortgageAmortization;
};
const getMortgageScenarioData = (state: IRootState) => {
    return state.scenarioData?.mortgageData;
};
const getTotalAmortization = (state: IRootState) => {
    return state.result?.housing?.amortization?.scenarioNow >= 0
        ? state.result?.housing?.amortization?.scenarioNow
        : state.result?.housing?.amortization?.now || 0;
};

export const AmortizationScenario = (props: IScenarioProps) => {
    const { onBack, onEngagement } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const scenarioMortgage = useSelector(getMortgageScenarioData);
    const scenarioAmortization = useSelector(getAmortizationScenarioData);
    const currentAmortization = useSelector(getTotalAmortization);
    const userData = useSelector((state: IRootState) => state.userData);
    const currentMortgage = getMortgage(userData, scenarioMortgage);

    if (currentMortgage <= 0) {
        return (
            <IncompleteStateScenario
                description={t("scenario:scenario-amortization-no-loans")}
                name={t("scenario:amortization-header")}
                onBack={onBack}
                category={t("dynamic:house")}
            />
        );
    }

    const scenarioActivated = !!scenarioAmortization;
    const income = householdIncomeForecastForYear(userData, 0).income;
    const price = scenarioMortgage?.housingValue || getHousingPriceByCalculationType(userData);
    const additionalMortgageAmount = userData.kalp?.additionalHousing?.data?.mortgage?.amount ?? 0;
    const amortizationRequirementRatio = convertToPercent(
        minAmortizationRate({
            price,
            loan: currentMortgage,
            income,
            additionalMortgageAmount,
        }),
    );
    const currentAmortizationRatio = getAmortizationRatio(currentAmortization, currentMortgage);
    const requirementsAsDefault = userData.household.amortizationRequirementsAsDefault;
    const usingRequirements = requirementsAsDefault ? !scenarioActivated : scenarioAmortization?.usingRequirements;
    const isBelowRequirements = requirementsAsDefault && currentAmortizationRatio < amortizationRequirementRatio;

    const resetScenario = () => {
        dispatch(resetAmortizationScenario());
    };
    const setScenario = (value: number) => {
        onEngagement(ScenarioEvent.AMORTIZATION);
        dispatch(setAmortizationScenario({ value }));
    };
    const setRequirementScenario = () => {
        onEngagement(ScenarioEvent.AMORTIZATION);
        dispatch(setAmortizationRequirementScenario());
    };
    const setAmortizationRequirements = (event) => {
        const checked = event.target.checked;
        if (requirementsAsDefault) {
            if (checked) {
                resetScenario();
            } else {
                setScenario(currentAmortization);
            }
        } else {
            if (checked) {
                setRequirementScenario();
            } else {
                resetScenario();
            }
        }
    };

    const disclaimer =
        t("scenario:amortization-disclaimer", {
            rate: amortizationRequirementRatio + " %",
        }) + (scenarioAmortization?.usingRequirements ? t("scenario:amortization-disclaimer-secondary") : "");
    return (
        <ScenarioTemplate
            name={t("scenario:amortization-header")}
            onBack={onBack}
            onReset={resetScenario}
            scenarioActivated={scenarioActivated}
            resetDisabled
            category={t("dynamic:house")}
        >
            <NumberComponent
                name="amortization_scenario"
                unit={t("common:sek-month")}
                onChange={setScenario}
                min={0}
                step={100}
                defaultMax={20000}
                label={t("scenario:amortization-label")}
                detailLabel={currentAmortizationRatio + " %"}
                value={currentAmortization}
            />
            <CheckboxComponent id="amortization-checkbox" checked={usingRequirements} onChange={setAmortizationRequirements}>
                {t("scenario:amortization-checkbox-label")}
            </CheckboxComponent>
            {!isBelowRequirements ? <DisclaimerComponent>{disclaimer}</DisclaimerComponent> : <ErrorTextComponent>{disclaimer}</ErrorTextComponent>}
        </ScenarioTemplate>
    );
};
