// Tax table 32 for 2025
export default [
    { tt: 32, fr: 0, to: 2000, v: 0, r: 0 },
    { tt: 32, fr: 2001, to: 2100, v: 150, r: 0 },
    { tt: 32, fr: 2101, to: 2200, v: 152, r: 0 },
    { tt: 32, fr: 2201, to: 2300, v: 163, r: 0 },
    { tt: 32, fr: 2301, to: 2400, v: 173, r: 0 },
    { tt: 32, fr: 2401, to: 2500, v: 184, r: 0 },
    { tt: 32, fr: 2501, to: 2600, v: 194, r: 0 },
    { tt: 32, fr: 2601, to: 2700, v: 205, r: 0 },
    { tt: 32, fr: 2701, to: 2800, v: 215, r: 0 },
    { tt: 32, fr: 2801, to: 2900, v: 217, r: 0 },
    { tt: 32, fr: 2901, to: 3000, v: 228, r: 0 },
    { tt: 32, fr: 3001, to: 3100, v: 238, r: 0 },
    { tt: 32, fr: 3101, to: 3200, v: 249, r: 0 },
    { tt: 32, fr: 3201, to: 3300, v: 259, r: 0 },
    { tt: 32, fr: 3301, to: 3400, v: 270, r: 0 },
    { tt: 32, fr: 3401, to: 3500, v: 272, r: 0 },
    { tt: 32, fr: 3501, to: 3600, v: 282, r: 0 },
    { tt: 32, fr: 3601, to: 3700, v: 293, r: 0 },
    { tt: 32, fr: 3701, to: 3800, v: 303, r: 0 },
    { tt: 32, fr: 3801, to: 3900, v: 314, r: 0 },
    { tt: 32, fr: 3901, to: 4000, v: 324, r: 0 },
    { tt: 32, fr: 4001, to: 4100, v: 327, r: 0 },
    { tt: 32, fr: 4101, to: 4200, v: 337, r: 0 },
    { tt: 32, fr: 4201, to: 4300, v: 348, r: 0 },
    { tt: 32, fr: 4301, to: 4400, v: 358, r: 0 },
    { tt: 32, fr: 4401, to: 4500, v: 369, r: 0 },
    { tt: 32, fr: 4501, to: 4600, v: 379, r: 0 },
    { tt: 32, fr: 4601, to: 4700, v: 381, r: 0 },
    { tt: 32, fr: 4701, to: 4800, v: 392, r: 0 },
    { tt: 32, fr: 4801, to: 4900, v: 402, r: 0 },
    { tt: 32, fr: 4901, to: 5000, v: 412, r: 0 },
    { tt: 32, fr: 5001, to: 5100, v: 422, r: 0 },
    { tt: 32, fr: 5101, to: 5200, v: 432, r: 0 },
    { tt: 32, fr: 5201, to: 5300, v: 442, r: 0 },
    { tt: 32, fr: 5301, to: 5400, v: 444, r: 0 },
    { tt: 32, fr: 5401, to: 5500, v: 454, r: 13 },
    { tt: 32, fr: 5501, to: 5600, v: 464, r: 38 },
    { tt: 32, fr: 5601, to: 5700, v: 474, r: 63 },
    { tt: 32, fr: 5701, to: 5800, v: 484, r: 87 },
    { tt: 32, fr: 5801, to: 5900, v: 494, r: 109 },
    { tt: 32, fr: 5901, to: 6000, v: 496, r: 134 },
    { tt: 32, fr: 6001, to: 6100, v: 506, r: 159 },
    { tt: 32, fr: 6101, to: 6200, v: 516, r: 184 },
    { tt: 32, fr: 6201, to: 6300, v: 526, r: 208 },
    { tt: 32, fr: 6301, to: 6400, v: 536, r: 233 },
    { tt: 32, fr: 6401, to: 6500, v: 546, r: 258 },
    { tt: 32, fr: 6501, to: 6600, v: 548, r: 283 },
    { tt: 32, fr: 6601, to: 6700, v: 558, r: 307 },
    { tt: 32, fr: 6701, to: 6800, v: 568, r: 332 },
    { tt: 32, fr: 6801, to: 6900, v: 578, r: 357 },
    { tt: 32, fr: 6901, to: 7000, v: 588, r: 382 },
    { tt: 32, fr: 7001, to: 7100, v: 598, r: 404 },
    { tt: 32, fr: 7101, to: 7200, v: 608, r: 428 },
    { tt: 32, fr: 7201, to: 7300, v: 628, r: 453 },
    { tt: 32, fr: 7301, to: 7400, v: 648, r: 478 },
    { tt: 32, fr: 7401, to: 7500, v: 668, r: 503 },
    { tt: 32, fr: 7501, to: 7600, v: 688, r: 527 },
    { tt: 32, fr: 7601, to: 7700, v: 708, r: 552 },
    { tt: 32, fr: 7701, to: 7800, v: 728, r: 577 },
    { tt: 32, fr: 7801, to: 7900, v: 748, r: 602 },
    { tt: 32, fr: 7901, to: 8000, v: 768, r: 626 },
    { tt: 32, fr: 8001, to: 8100, v: 788, r: 651 },
    { tt: 32, fr: 8101, to: 8200, v: 808, r: 676 },
    { tt: 32, fr: 8201, to: 8300, v: 828, r: 698 },
    { tt: 32, fr: 8301, to: 8400, v: 848, r: 723 },
    { tt: 32, fr: 8401, to: 8500, v: 868, r: 747 },
    { tt: 32, fr: 8501, to: 8600, v: 888, r: 772 },
    { tt: 32, fr: 8601, to: 8700, v: 908, r: 797 },
    { tt: 32, fr: 8701, to: 8800, v: 928, r: 822 },
    { tt: 32, fr: 8801, to: 8900, v: 948, r: 846 },
    { tt: 32, fr: 8901, to: 9000, v: 968, r: 871 },
    { tt: 32, fr: 9001, to: 9100, v: 988, r: 896 },
    { tt: 32, fr: 9101, to: 9200, v: 1008, r: 921 },
    { tt: 32, fr: 9201, to: 9300, v: 1028, r: 945 },
    { tt: 32, fr: 9301, to: 9400, v: 1048, r: 970 },
    { tt: 32, fr: 9401, to: 9500, v: 1068, r: 992 },
    { tt: 32, fr: 9501, to: 9600, v: 1089, r: 1017 },
    { tt: 32, fr: 9601, to: 9700, v: 1108, r: 1033 },
    { tt: 32, fr: 9701, to: 9800, v: 1128, r: 1053 },
    { tt: 32, fr: 9801, to: 9900, v: 1149, r: 1072 },
    { tt: 32, fr: 9901, to: 10000, v: 1169, r: 1094 },
    { tt: 32, fr: 10001, to: 10100, v: 1189, r: 1113 },
    { tt: 32, fr: 10101, to: 10200, v: 1209, r: 1132 },
    { tt: 32, fr: 10201, to: 10300, v: 1229, r: 1151 },
    { tt: 32, fr: 10301, to: 10400, v: 1249, r: 1172 },
    { tt: 32, fr: 10401, to: 10500, v: 1269, r: 1191 },
    { tt: 32, fr: 10501, to: 10600, v: 1289, r: 1210 },
    { tt: 32, fr: 10601, to: 10700, v: 1309, r: 1228 },
    { tt: 32, fr: 10701, to: 10800, v: 1329, r: 1250 },
    { tt: 32, fr: 10801, to: 10900, v: 1349, r: 1269 },
    { tt: 32, fr: 10901, to: 11000, v: 1369, r: 1288 },
    { tt: 32, fr: 11001, to: 11100, v: 1389, r: 1306 },
    { tt: 32, fr: 11101, to: 11200, v: 1409, r: 1328 },
    { tt: 32, fr: 11201, to: 11300, v: 1429, r: 1347 },
    { tt: 32, fr: 11301, to: 11400, v: 1449, r: 1366 },
    { tt: 32, fr: 11401, to: 11500, v: 1469, r: 1384 },
    { tt: 32, fr: 11501, to: 11600, v: 1489, r: 1406 },
    { tt: 32, fr: 11601, to: 11700, v: 1509, r: 1425 },
    { tt: 32, fr: 11701, to: 11800, v: 1529, r: 1443 },
    { tt: 32, fr: 11801, to: 11900, v: 1549, r: 1462 },
    { tt: 32, fr: 11901, to: 12000, v: 1569, r: 1484 },
    { tt: 32, fr: 12001, to: 12100, v: 1589, r: 1503 },
    { tt: 32, fr: 12101, to: 12200, v: 1609, r: 1521 },
    { tt: 32, fr: 12201, to: 12300, v: 1629, r: 1543 },
    { tt: 32, fr: 12301, to: 12400, v: 1649, r: 1562 },
    { tt: 32, fr: 12401, to: 12500, v: 1669, r: 1581 },
    { tt: 32, fr: 12501, to: 12600, v: 1689, r: 1599 },
    { tt: 32, fr: 12601, to: 12700, v: 1709, r: 1621 },
    { tt: 32, fr: 12701, to: 12800, v: 1729, r: 1640 },
    { tt: 32, fr: 12801, to: 12900, v: 1749, r: 1658 },
    { tt: 32, fr: 12901, to: 13000, v: 1769, r: 1677 },
    { tt: 32, fr: 13001, to: 13100, v: 1789, r: 1699 },
    { tt: 32, fr: 13101, to: 13200, v: 1809, r: 1718 },
    { tt: 32, fr: 13201, to: 13300, v: 1829, r: 1736 },
    { tt: 32, fr: 13301, to: 13400, v: 1849, r: 1755 },
    { tt: 32, fr: 13401, to: 13500, v: 1870, r: 1777 },
    { tt: 32, fr: 13501, to: 13600, v: 1890, r: 1796 },
    { tt: 32, fr: 13601, to: 13700, v: 1910, r: 1814 },
    { tt: 32, fr: 13701, to: 13800, v: 1931, r: 1833 },
    { tt: 32, fr: 13801, to: 13900, v: 1951, r: 1855 },
    { tt: 32, fr: 13901, to: 14000, v: 1971, r: 1873 },
    { tt: 32, fr: 14001, to: 14100, v: 1992, r: 1892 },
    { tt: 32, fr: 14101, to: 14200, v: 2012, r: 1911 },
    { tt: 32, fr: 14201, to: 14300, v: 2031, r: 1933 },
    { tt: 32, fr: 14301, to: 14400, v: 2050, r: 1951 },
    { tt: 32, fr: 14401, to: 14500, v: 2070, r: 1970 },
    { tt: 32, fr: 14501, to: 14600, v: 2089, r: 1992 },
    { tt: 32, fr: 14601, to: 14700, v: 2108, r: 2011 },
    { tt: 32, fr: 14701, to: 14800, v: 2127, r: 2029 },
    { tt: 32, fr: 14801, to: 14900, v: 2147, r: 2048 },
    { tt: 32, fr: 14901, to: 15000, v: 2166, r: 2070 },
    { tt: 32, fr: 15001, to: 15100, v: 2185, r: 2088 },
    { tt: 32, fr: 15101, to: 15200, v: 2205, r: 2107 },
    { tt: 32, fr: 15201, to: 15300, v: 2224, r: 2126 },
    { tt: 32, fr: 15301, to: 15400, v: 2243, r: 2148 },
    { tt: 32, fr: 15401, to: 15500, v: 2263, r: 2166 },
    { tt: 32, fr: 15501, to: 15600, v: 2282, r: 2185 },
    { tt: 32, fr: 15601, to: 15700, v: 2301, r: 2204 },
    { tt: 32, fr: 15701, to: 15800, v: 2321, r: 2226 },
    { tt: 32, fr: 15801, to: 15900, v: 2341, r: 2244 },
    { tt: 32, fr: 15901, to: 16000, v: 2366, r: 2271 },
    { tt: 32, fr: 16001, to: 16100, v: 2391, r: 2295 },
    { tt: 32, fr: 16101, to: 16200, v: 2416, r: 2322 },
    { tt: 32, fr: 16201, to: 16300, v: 2442, r: 2346 },
    { tt: 32, fr: 16301, to: 16400, v: 2467, r: 2373 },
    { tt: 32, fr: 16401, to: 16500, v: 2492, r: 2400 },
    { tt: 32, fr: 16501, to: 16600, v: 2517, r: 2424 },
    { tt: 32, fr: 16601, to: 16700, v: 2542, r: 2451 },
    { tt: 32, fr: 16701, to: 16800, v: 2567, r: 2475 },
    { tt: 32, fr: 16801, to: 16900, v: 2592, r: 2502 },
    { tt: 32, fr: 16901, to: 17000, v: 2618, r: 2527 },
    { tt: 32, fr: 17001, to: 17100, v: 2643, r: 2553 },
    { tt: 32, fr: 17101, to: 17200, v: 2668, r: 2578 },
    { tt: 32, fr: 17201, to: 17300, v: 2693, r: 2604 },
    { tt: 32, fr: 17301, to: 17400, v: 2718, r: 2629 },
    { tt: 32, fr: 17401, to: 17500, v: 2743, r: 2656 },
    { tt: 32, fr: 17501, to: 17600, v: 2769, r: 2682 },
    { tt: 32, fr: 17601, to: 17700, v: 2794, r: 2707 },
    { tt: 32, fr: 17701, to: 17800, v: 2819, r: 2733 },
    { tt: 32, fr: 17801, to: 17900, v: 2844, r: 2758 },
    { tt: 32, fr: 17901, to: 18000, v: 2869, r: 2785 },
    { tt: 32, fr: 18001, to: 18100, v: 2894, r: 2809 },
    { tt: 32, fr: 18101, to: 18200, v: 2919, r: 2836 },
    { tt: 32, fr: 18201, to: 18300, v: 2945, r: 2860 },
    { tt: 32, fr: 18301, to: 18400, v: 2970, r: 2887 },
    { tt: 32, fr: 18401, to: 18500, v: 2995, r: 2914 },
    { tt: 32, fr: 18501, to: 18600, v: 3020, r: 2938 },
    { tt: 32, fr: 18601, to: 18700, v: 3045, r: 2965 },
    { tt: 32, fr: 18701, to: 18800, v: 3070, r: 2989 },
    { tt: 32, fr: 18801, to: 18900, v: 3096, r: 3016 },
    { tt: 32, fr: 18901, to: 19000, v: 3121, r: 3040 },
    { tt: 32, fr: 19001, to: 19100, v: 3146, r: 3067 },
    { tt: 32, fr: 19101, to: 19200, v: 3171, r: 3091 },
    { tt: 32, fr: 19201, to: 19300, v: 3196, r: 3118 },
    { tt: 32, fr: 19301, to: 19400, v: 3221, r: 3145 },
    { tt: 32, fr: 19401, to: 19500, v: 3246, r: 3169 },
    { tt: 32, fr: 19501, to: 19600, v: 3272, r: 3196 },
    { tt: 32, fr: 19601, to: 19700, v: 3297, r: 3220 },
    { tt: 32, fr: 19701, to: 19800, v: 3322, r: 3247 },
    { tt: 32, fr: 19801, to: 19900, v: 3347, r: 3271 },
    { tt: 32, fr: 19901, to: 20000, v: 3372, r: 3298 },
    { tt: 32, fr: 20001, to: 20200, v: 3423, r: 3349 },
    { tt: 32, fr: 20201, to: 20400, v: 3473, r: 3399 },
    { tt: 32, fr: 20401, to: 20600, v: 3523, r: 3449 },
    { tt: 32, fr: 20601, to: 20800, v: 3573, r: 3498 },
    { tt: 32, fr: 20801, to: 21000, v: 3624, r: 3548 },
    { tt: 32, fr: 21001, to: 21200, v: 3674, r: 3597 },
    { tt: 32, fr: 21201, to: 21400, v: 3724, r: 3649 },
    { tt: 32, fr: 21401, to: 21600, v: 3775, r: 3699 },
    { tt: 32, fr: 21601, to: 21800, v: 3825, r: 3748 },
    { tt: 32, fr: 21801, to: 22000, v: 3875, r: 3798 },
    { tt: 32, fr: 22001, to: 22200, v: 3926, r: 3847 },
    { tt: 32, fr: 22201, to: 22400, v: 3976, r: 3897 },
    { tt: 32, fr: 22401, to: 22600, v: 4026, r: 3946 },
    { tt: 32, fr: 22601, to: 22800, v: 4077, r: 3996 },
    { tt: 32, fr: 22801, to: 23000, v: 4127, r: 4045 },
    { tt: 32, fr: 23001, to: 23200, v: 4179, r: 4095 },
    { tt: 32, fr: 23201, to: 23400, v: 4231, r: 4147 },
    { tt: 32, fr: 23401, to: 23600, v: 4283, r: 4196 },
    { tt: 32, fr: 23601, to: 23800, v: 4335, r: 4246 },
    { tt: 32, fr: 23801, to: 24000, v: 4387, r: 4295 },
    { tt: 32, fr: 24001, to: 24200, v: 4439, r: 4345 },
    { tt: 32, fr: 24201, to: 24400, v: 4491, r: 4394 },
    { tt: 32, fr: 24401, to: 24600, v: 4542, r: 4444 },
    { tt: 32, fr: 24601, to: 24800, v: 4594, r: 4496 },
    { tt: 32, fr: 24801, to: 25000, v: 4646, r: 4548 },
    { tt: 32, fr: 25001, to: 25200, v: 4698, r: 4597 },
    { tt: 32, fr: 25201, to: 25400, v: 4750, r: 4649 },
    { tt: 32, fr: 25401, to: 25600, v: 4802, r: 4699 },
    { tt: 32, fr: 25601, to: 25800, v: 4854, r: 4751 },
    { tt: 32, fr: 25801, to: 26000, v: 4906, r: 4800 },
    { tt: 32, fr: 26001, to: 26200, v: 4958, r: 4853 },
    { tt: 32, fr: 26201, to: 26400, v: 5010, r: 4902 },
    { tt: 32, fr: 26401, to: 26600, v: 5062, r: 4954 },
    { tt: 32, fr: 26601, to: 26800, v: 5114, r: 5004 },
    { tt: 32, fr: 26801, to: 27000, v: 5166, r: 5056 },
    { tt: 32, fr: 27001, to: 27200, v: 5218, r: 5105 },
    { tt: 32, fr: 27201, to: 27400, v: 5270, r: 5157 },
    { tt: 32, fr: 27401, to: 27600, v: 5322, r: 5207 },
    { tt: 32, fr: 27601, to: 27800, v: 5374, r: 5259 },
    { tt: 32, fr: 27801, to: 28000, v: 5426, r: 5311 },
    { tt: 32, fr: 28001, to: 28200, v: 5478, r: 5360 },
    { tt: 32, fr: 28201, to: 28400, v: 5530, r: 5412 },
    { tt: 32, fr: 28401, to: 28600, v: 5582, r: 5462 },
    { tt: 32, fr: 28601, to: 28800, v: 5634, r: 5514 },
    { tt: 32, fr: 28801, to: 29000, v: 5686, r: 5563 },
    { tt: 32, fr: 29001, to: 29200, v: 5737, r: 5616 },
    { tt: 32, fr: 29201, to: 29400, v: 5790, r: 5665 },
    { tt: 32, fr: 29401, to: 29600, v: 5841, r: 5717 },
    { tt: 32, fr: 29601, to: 29800, v: 5893, r: 5767 },
    { tt: 32, fr: 29801, to: 30000, v: 5945, r: 5819 },
    { tt: 32, fr: 30001, to: 30200, v: 5997, r: 5868 },
    { tt: 32, fr: 30201, to: 30400, v: 6049, r: 5920 },
    { tt: 32, fr: 30401, to: 30600, v: 6101, r: 5972 },
    { tt: 32, fr: 30601, to: 30800, v: 6153, r: 6022 },
    { tt: 32, fr: 30801, to: 31000, v: 6205, r: 6074 },
    { tt: 32, fr: 31001, to: 31200, v: 6257, r: 6123 },
    { tt: 32, fr: 31201, to: 31400, v: 6309, r: 6175 },
    { tt: 32, fr: 31401, to: 31600, v: 6361, r: 6225 },
    { tt: 32, fr: 31601, to: 31800, v: 6413, r: 6277 },
    { tt: 32, fr: 31801, to: 32000, v: 6465, r: 6326 },
    { tt: 32, fr: 32001, to: 32200, v: 6517, r: 6379 },
    { tt: 32, fr: 32201, to: 32400, v: 6569, r: 6429 },
    { tt: 32, fr: 32401, to: 32600, v: 6621, r: 6483 },
    { tt: 32, fr: 32601, to: 32800, v: 6673, r: 6533 },
    { tt: 32, fr: 32801, to: 33000, v: 6725, r: 6587 },
    { tt: 32, fr: 33001, to: 33200, v: 6777, r: 6637 },
    { tt: 32, fr: 33201, to: 33400, v: 6829, r: 6691 },
    { tt: 32, fr: 33401, to: 33600, v: 6881, r: 6744 },
    { tt: 32, fr: 33601, to: 33800, v: 6933, r: 6795 },
    { tt: 32, fr: 33801, to: 34000, v: 6985, r: 6848 },
    { tt: 32, fr: 34001, to: 34200, v: 7036, r: 6899 },
    { tt: 32, fr: 34201, to: 34400, v: 7088, r: 6952 },
    { tt: 32, fr: 34401, to: 34600, v: 7140, r: 7003 },
    { tt: 32, fr: 34601, to: 34800, v: 7192, r: 7056 },
    { tt: 32, fr: 34801, to: 35000, v: 7244, r: 7107 },
    { tt: 32, fr: 35001, to: 35200, v: 7296, r: 7160 },
    { tt: 32, fr: 35201, to: 35400, v: 7348, r: 7211 },
    { tt: 32, fr: 35401, to: 35600, v: 7400, r: 7264 },
    { tt: 32, fr: 35601, to: 35800, v: 7452, r: 7315 },
    { tt: 32, fr: 35801, to: 36000, v: 7504, r: 7368 },
    { tt: 32, fr: 36001, to: 36200, v: 7556, r: 7421 },
    { tt: 32, fr: 36201, to: 36400, v: 7608, r: 7472 },
    { tt: 32, fr: 36401, to: 36600, v: 7660, r: 7525 },
    { tt: 32, fr: 36601, to: 36800, v: 7712, r: 7576 },
    { tt: 32, fr: 36801, to: 37000, v: 7764, r: 7629 },
    { tt: 32, fr: 37001, to: 37200, v: 7816, r: 7680 },
    { tt: 32, fr: 37201, to: 37400, v: 7868, r: 7733 },
    { tt: 32, fr: 37401, to: 37600, v: 7920, r: 7784 },
    { tt: 32, fr: 37601, to: 37800, v: 7972, r: 7837 },
    { tt: 32, fr: 37801, to: 38000, v: 8024, r: 7888 },
    { tt: 32, fr: 38001, to: 38200, v: 8076, r: 7941 },
    { tt: 32, fr: 38201, to: 38400, v: 8128, r: 7992 },
    { tt: 32, fr: 38401, to: 38600, v: 8180, r: 8045 },
    { tt: 32, fr: 38601, to: 38800, v: 8231, r: 8096 },
    { tt: 32, fr: 38801, to: 39000, v: 8283, r: 8147 },
    { tt: 32, fr: 39001, to: 39200, v: 8335, r: 8197 },
    { tt: 32, fr: 39201, to: 39400, v: 8386, r: 8251 },
    { tt: 32, fr: 39401, to: 39600, v: 8439, r: 8301 },
    { tt: 32, fr: 39601, to: 39800, v: 8503, r: 8365 },
    { tt: 32, fr: 39801, to: 40000, v: 8567, r: 8429 },
    { tt: 32, fr: 40001, to: 40200, v: 8631, r: 8493 },
    { tt: 32, fr: 40201, to: 40400, v: 8695, r: 8557 },
    { tt: 32, fr: 40401, to: 40600, v: 8759, r: 8621 },
    { tt: 32, fr: 40601, to: 40800, v: 8823, r: 8685 },
    { tt: 32, fr: 40801, to: 41000, v: 8887, r: 8749 },
    { tt: 32, fr: 41001, to: 41200, v: 8951, r: 8813 },
    { tt: 32, fr: 41201, to: 41400, v: 9015, r: 8877 },
    { tt: 32, fr: 41401, to: 41600, v: 9079, r: 8941 },
    { tt: 32, fr: 41601, to: 41800, v: 9143, r: 9005 },
    { tt: 32, fr: 41801, to: 42000, v: 9207, r: 9069 },
    { tt: 32, fr: 42001, to: 42200, v: 9271, r: 9133 },
    { tt: 32, fr: 42201, to: 42400, v: 9335, r: 9197 },
    { tt: 32, fr: 42401, to: 42600, v: 9399, r: 9261 },
    { tt: 32, fr: 42601, to: 42800, v: 9463, r: 9325 },
    { tt: 32, fr: 42801, to: 43000, v: 9527, r: 9389 },
    { tt: 32, fr: 43001, to: 43200, v: 9591, r: 9453 },
    { tt: 32, fr: 43201, to: 43400, v: 9655, r: 9517 },
    { tt: 32, fr: 43401, to: 43600, v: 9719, r: 9581 },
    { tt: 32, fr: 43601, to: 43800, v: 9783, r: 9645 },
    { tt: 32, fr: 43801, to: 44000, v: 9847, r: 9709 },
    { tt: 32, fr: 44001, to: 44200, v: 9911, r: 9773 },
    { tt: 32, fr: 44201, to: 44400, v: 9975, r: 9837 },
    { tt: 32, fr: 44401, to: 44600, v: 10039, r: 9901 },
    { tt: 32, fr: 44601, to: 44800, v: 10103, r: 9965 },
    { tt: 32, fr: 44801, to: 45000, v: 10167, r: 10029 },
    { tt: 32, fr: 45001, to: 45200, v: 10231, r: 10093 },
    { tt: 32, fr: 45201, to: 45400, v: 10295, r: 10157 },
    { tt: 32, fr: 45401, to: 45600, v: 10359, r: 10221 },
    { tt: 32, fr: 45601, to: 45800, v: 10423, r: 10285 },
    { tt: 32, fr: 45801, to: 46000, v: 10487, r: 10349 },
    { tt: 32, fr: 46001, to: 46200, v: 10551, r: 10413 },
    { tt: 32, fr: 46201, to: 46400, v: 10615, r: 10477 },
    { tt: 32, fr: 46401, to: 46600, v: 10679, r: 10541 },
    { tt: 32, fr: 46601, to: 46800, v: 10743, r: 10605 },
    { tt: 32, fr: 46801, to: 47000, v: 10807, r: 10669 },
    { tt: 32, fr: 47001, to: 47200, v: 10871, r: 10733 },
    { tt: 32, fr: 47201, to: 47400, v: 10935, r: 10797 },
    { tt: 32, fr: 47401, to: 47600, v: 10999, r: 10861 },
    { tt: 32, fr: 47601, to: 47800, v: 11063, r: 10925 },
    { tt: 32, fr: 47801, to: 48000, v: 11127, r: 10989 },
    { tt: 32, fr: 48001, to: 48200, v: 11191, r: 11053 },
    { tt: 32, fr: 48201, to: 48400, v: 11255, r: 11117 },
    { tt: 32, fr: 48401, to: 48600, v: 11319, r: 11181 },
    { tt: 32, fr: 48601, to: 48800, v: 11383, r: 11245 },
    { tt: 32, fr: 48801, to: 49000, v: 11447, r: 11309 },
    { tt: 32, fr: 49001, to: 49200, v: 11511, r: 11373 },
    { tt: 32, fr: 49201, to: 49400, v: 11575, r: 11437 },
    { tt: 32, fr: 49401, to: 49600, v: 11639, r: 11501 },
    { tt: 32, fr: 49601, to: 49800, v: 11703, r: 11565 },
    { tt: 32, fr: 49801, to: 50000, v: 11767, r: 11629 },
    { tt: 32, fr: 50001, to: 50200, v: 11831, r: 11693 },
    { tt: 32, fr: 50201, to: 50400, v: 11895, r: 11757 },
    { tt: 32, fr: 50401, to: 50600, v: 11959, r: 11821 },
    { tt: 32, fr: 50601, to: 50800, v: 12023, r: 11885 },
    { tt: 32, fr: 50801, to: 51000, v: 12087, r: 11949 },
    { tt: 32, fr: 51001, to: 51200, v: 12151, r: 12013 },
    { tt: 32, fr: 51201, to: 51400, v: 12215, r: 12077 },
    { tt: 32, fr: 51401, to: 51600, v: 12279, r: 12141 },
    { tt: 32, fr: 51601, to: 51800, v: 12343, r: 12205 },
    { tt: 32, fr: 51801, to: 52000, v: 12407, r: 12269 },
    { tt: 32, fr: 52001, to: 52200, v: 12471, r: 12333 },
    { tt: 32, fr: 52201, to: 52400, v: 12535, r: 12397 },
    { tt: 32, fr: 52401, to: 52600, v: 12599, r: 12461 },
    { tt: 32, fr: 52601, to: 52800, v: 12663, r: 12525 },
    { tt: 32, fr: 52801, to: 53000, v: 12727, r: 12589 },
    { tt: 32, fr: 53001, to: 53200, v: 12791, r: 12653 },
    { tt: 32, fr: 53201, to: 53400, v: 12855, r: 12717 },
    { tt: 32, fr: 53401, to: 53600, v: 12921, r: 12781 },
    { tt: 32, fr: 53601, to: 53800, v: 13025, r: 12883 },
    { tt: 32, fr: 53801, to: 54000, v: 13129, r: 12987 },
    { tt: 32, fr: 54001, to: 54200, v: 13233, r: 13091 },
    { tt: 32, fr: 54201, to: 54400, v: 13337, r: 13195 },
    { tt: 32, fr: 54401, to: 54600, v: 13441, r: 13299 },
    { tt: 32, fr: 54601, to: 54800, v: 13545, r: 13403 },
    { tt: 32, fr: 54801, to: 55000, v: 13649, r: 13507 },
    { tt: 32, fr: 55001, to: 55200, v: 13753, r: 13608 },
    { tt: 32, fr: 55201, to: 55400, v: 13857, r: 13712 },
    { tt: 32, fr: 55401, to: 55600, v: 13961, r: 13816 },
    { tt: 32, fr: 55601, to: 55800, v: 14065, r: 13920 },
    { tt: 32, fr: 55801, to: 56000, v: 14169, r: 14024 },
    { tt: 32, fr: 56001, to: 56200, v: 14273, r: 14128 },
    { tt: 32, fr: 56201, to: 56400, v: 14377, r: 14232 },
    { tt: 32, fr: 56401, to: 56600, v: 14481, r: 14336 },
    { tt: 32, fr: 56601, to: 56800, v: 14585, r: 14437 },
    { tt: 32, fr: 56801, to: 57000, v: 14689, r: 14541 },
    { tt: 32, fr: 57001, to: 57200, v: 14793, r: 14645 },
    { tt: 32, fr: 57201, to: 57400, v: 14897, r: 14749 },
    { tt: 32, fr: 57401, to: 57600, v: 15001, r: 14853 },
    { tt: 32, fr: 57601, to: 57800, v: 15105, r: 14957 },
    { tt: 32, fr: 57801, to: 58000, v: 15209, r: 15061 },
    { tt: 32, fr: 58001, to: 58200, v: 15313, r: 15165 },
    { tt: 32, fr: 58201, to: 58400, v: 15417, r: 15269 },
    { tt: 32, fr: 58401, to: 58600, v: 15521, r: 15371 },
    { tt: 32, fr: 58601, to: 58800, v: 15625, r: 15475 },
    { tt: 32, fr: 58801, to: 59000, v: 15729, r: 15579 },
    { tt: 32, fr: 59001, to: 59200, v: 15833, r: 15683 },
    { tt: 32, fr: 59201, to: 59400, v: 15937, r: 15787 },
    { tt: 32, fr: 59401, to: 59600, v: 16041, r: 15891 },
    { tt: 32, fr: 59601, to: 59800, v: 16145, r: 15995 },
    { tt: 32, fr: 59801, to: 60000, v: 16249, r: 16099 },
    { tt: 32, fr: 60001, to: 60200, v: 16353, r: 16200 },
    { tt: 32, fr: 60201, to: 60400, v: 16457, r: 16304 },
    { tt: 32, fr: 60401, to: 60600, v: 16561, r: 16408 },
    { tt: 32, fr: 60601, to: 60800, v: 16665, r: 16512 },
    { tt: 32, fr: 60801, to: 61000, v: 16769, r: 16616 },
    { tt: 32, fr: 61001, to: 61200, v: 16873, r: 16719 },
    { tt: 32, fr: 61201, to: 61400, v: 16977, r: 16823 },
    { tt: 32, fr: 61401, to: 61600, v: 17081, r: 16927 },
    { tt: 32, fr: 61601, to: 61800, v: 17185, r: 17031 },
    { tt: 32, fr: 61801, to: 62000, v: 17289, r: 17135 },
    { tt: 32, fr: 62001, to: 62200, v: 17393, r: 17239 },
    { tt: 32, fr: 62201, to: 62400, v: 17497, r: 17343 },
    { tt: 32, fr: 62401, to: 62600, v: 17601, r: 17447 },
    { tt: 32, fr: 62601, to: 62800, v: 17705, r: 17551 },
    { tt: 32, fr: 62801, to: 63000, v: 17809, r: 17655 },
    { tt: 32, fr: 63001, to: 63200, v: 17913, r: 17759 },
    { tt: 32, fr: 63201, to: 63400, v: 18017, r: 17863 },
    { tt: 32, fr: 63401, to: 63600, v: 18121, r: 17967 },
    { tt: 32, fr: 63601, to: 63800, v: 18225, r: 18071 },
    { tt: 32, fr: 63801, to: 64000, v: 18329, r: 18175 },
    { tt: 32, fr: 64001, to: 64200, v: 18433, r: 18279 },
    { tt: 32, fr: 64201, to: 64400, v: 18537, r: 18383 },
    { tt: 32, fr: 64401, to: 64600, v: 18641, r: 18487 },
    { tt: 32, fr: 64601, to: 64800, v: 18745, r: 18591 },
    { tt: 32, fr: 64801, to: 65000, v: 18849, r: 18695 },
    { tt: 32, fr: 65001, to: 65200, v: 18953, r: 18799 },
    { tt: 32, fr: 65201, to: 65400, v: 19057, r: 18903 },
    { tt: 32, fr: 65401, to: 65600, v: 19161, r: 19007 },
    { tt: 32, fr: 65601, to: 65800, v: 19265, r: 19111 },
    { tt: 32, fr: 65801, to: 66000, v: 19369, r: 19215 },
    { tt: 32, fr: 66001, to: 66200, v: 19473, r: 19319 },
    { tt: 32, fr: 66201, to: 66400, v: 19577, r: 19423 },
    { tt: 32, fr: 66401, to: 66600, v: 19681, r: 19527 },
    { tt: 32, fr: 66601, to: 66800, v: 19785, r: 19631 },
    { tt: 32, fr: 66801, to: 67000, v: 19889, r: 19735 },
    { tt: 32, fr: 67001, to: 67200, v: 19993, r: 19839 },
    { tt: 32, fr: 67201, to: 67400, v: 20097, r: 19943 },
    { tt: 32, fr: 67401, to: 67600, v: 20201, r: 20047 },
    { tt: 32, fr: 67601, to: 67800, v: 20305, r: 20151 },
    { tt: 32, fr: 67801, to: 68000, v: 20409, r: 20255 },
    { tt: 32, fr: 68001, to: 68200, v: 20513, r: 20359 },
    { tt: 32, fr: 68201, to: 68400, v: 20617, r: 20463 },
    { tt: 32, fr: 68401, to: 68600, v: 20721, r: 20567 },
    { tt: 32, fr: 68601, to: 68800, v: 20825, r: 20671 },
    { tt: 32, fr: 68801, to: 69000, v: 20929, r: 20775 },
    { tt: 32, fr: 69001, to: 69200, v: 21033, r: 20879 },
    { tt: 32, fr: 69201, to: 69400, v: 21137, r: 20983 },
    { tt: 32, fr: 69401, to: 69600, v: 21241, r: 21087 },
    { tt: 32, fr: 69601, to: 69800, v: 21345, r: 21191 },
    { tt: 32, fr: 69801, to: 70000, v: 21449, r: 21295 },
    { tt: 32, fr: 70001, to: 70200, v: 21553, r: 21399 },
    { tt: 32, fr: 70201, to: 70400, v: 21657, r: 21503 },
    { tt: 32, fr: 70401, to: 70600, v: 21761, r: 21607 },
    { tt: 32, fr: 70601, to: 70800, v: 21865, r: 21711 },
    { tt: 32, fr: 70801, to: 71000, v: 21969, r: 21815 },
    { tt: 32, fr: 71001, to: 71200, v: 22073, r: 21919 },
    { tt: 32, fr: 71201, to: 71400, v: 22177, r: 22023 },
    { tt: 32, fr: 71401, to: 71600, v: 22281, r: 22127 },
    { tt: 32, fr: 71601, to: 71800, v: 22385, r: 22231 },
    { tt: 32, fr: 71801, to: 72000, v: 22489, r: 22335 },
    { tt: 32, fr: 72001, to: 72200, v: 22593, r: 22439 },
    { tt: 32, fr: 72201, to: 72400, v: 22697, r: 22543 },
    { tt: 32, fr: 72401, to: 72600, v: 22801, r: 22647 },
    { tt: 32, fr: 72601, to: 72800, v: 22905, r: 22751 },
    { tt: 32, fr: 72801, to: 73000, v: 23009, r: 22855 },
    { tt: 32, fr: 73001, to: 73200, v: 23113, r: 22959 },
    { tt: 32, fr: 73201, to: 73400, v: 23217, r: 23063 },
    { tt: 32, fr: 73401, to: 73600, v: 23321, r: 23167 },
    { tt: 32, fr: 73601, to: 73800, v: 23425, r: 23271 },
    { tt: 32, fr: 73801, to: 74000, v: 23529, r: 23375 },
    { tt: 32, fr: 74001, to: 74200, v: 23633, r: 23479 },
    { tt: 32, fr: 74201, to: 74400, v: 23737, r: 23583 },
    { tt: 32, fr: 74401, to: 74600, v: 23841, r: 23687 },
    { tt: 32, fr: 74601, to: 74800, v: 23945, r: 23791 },
    { tt: 32, fr: 74801, to: 75000, v: 24049, r: 23895 },
    { tt: 32, fr: 75001, to: 75200, v: 24153, r: 23999 },
    { tt: 32, fr: 75201, to: 75400, v: 24257, r: 24103 },
    { tt: 32, fr: 75401, to: 75600, v: 24361, r: 24207 },
    { tt: 32, fr: 75601, to: 75800, v: 24465, r: 24311 },
    { tt: 32, fr: 75801, to: 76000, v: 24569, r: 24415 },
    { tt: 32, fr: 76001, to: 76200, v: 24673, r: 24519 },
    { tt: 32, fr: 76201, to: 76400, v: 24777, r: 24623 },
    { tt: 32, fr: 76401, to: 76600, v: 24881, r: 24727 },
    { tt: 32, fr: 76601, to: 76800, v: 24985, r: 24831 },
    { tt: 32, fr: 76801, to: 77000, v: 25089, r: 24935 },
    { tt: 32, fr: 77001, to: 77200, v: 25193, r: 25039 },
    { tt: 32, fr: 77201, to: 77400, v: 25297, r: 25143 },
    { tt: 32, fr: 77401, to: 77600, v: 25401, r: 25247 },
    { tt: 32, fr: 77601, to: 77800, v: 25505, r: 25351 },
    { tt: 32, fr: 77801, to: 78000, v: 25609, r: 25455 },
    { tt: 32, fr: 78001, to: 78200, v: 25713, r: 25559 },
    { tt: 32, fr: 78201, to: 78400, v: 25817, r: 25663 },
    { tt: 32, fr: 78401, to: 78600, v: 25921, r: 25767 },
    { tt: 32, fr: 78601, to: 78800, v: 26025, r: 25871 },
    { tt: 32, fr: 78801, to: 79000, v: 26129, r: 25975 },
    { tt: 32, fr: 79001, to: 79200, v: 26233, r: 26079 },
    { tt: 32, fr: 79201, to: 79400, v: 26337, r: 26183 },
    { tt: 32, fr: 79401, to: 79600, v: 26441, r: 26287 },
    { tt: 32, fr: 79601, to: 79800, v: 26545, r: 26391 },
    { tt: 32, fr: 79801, to: 80000, v: 26649, r: 26495 },
    { tt: 32, fr: 80001, to: 80800, v: 33, r: 33 },
    { tt: 32, fr: 80801, to: 81600, v: 34, r: 33 },
    { tt: 32, fr: 81601, to: 82800, v: 34, r: 34 },
    { tt: 32, fr: 82801, to: 84400, v: 34, r: 34 },
    { tt: 32, fr: 84401, to: 85400, v: 34, r: 34 },
    { tt: 32, fr: 85401, to: 86200, v: 35, r: 34 },
    { tt: 32, fr: 86201, to: 87200, v: 35, r: 35 },
    { tt: 32, fr: 87201, to: 88600, v: 35, r: 35 },
    { tt: 32, fr: 88601, to: 89600, v: 35, r: 35 },
    { tt: 32, fr: 89601, to: 90600, v: 35, r: 35 },
    { tt: 32, fr: 90601, to: 91400, v: 36, r: 35 },
    { tt: 32, fr: 91401, to: 93200, v: 36, r: 36 },
    { tt: 32, fr: 93201, to: 93600, v: 36, r: 36 },
    { tt: 32, fr: 93601, to: 96400, v: 36, r: 36 },
    { tt: 32, fr: 96401, to: 97200, v: 37, r: 36 },
    { tt: 32, fr: 97201, to: 97400, v: 37, r: 36 },
    { tt: 32, fr: 97401, to: 98200, v: 37, r: 37 },
    { tt: 32, fr: 98201, to: 101000, v: 37, r: 37 },
    { tt: 32, fr: 101001, to: 103000, v: 37, r: 37 },
    { tt: 32, fr: 103001, to: 103800, v: 38, r: 37 },
    { tt: 32, fr: 103801, to: 104000, v: 38, r: 37 },
    { tt: 32, fr: 104001, to: 106600, v: 38, r: 38 },
    { tt: 32, fr: 106601, to: 109800, v: 38, r: 38 },
    { tt: 32, fr: 109801, to: 110200, v: 38, r: 38 },
    { tt: 32, fr: 110201, to: 110600, v: 38, r: 38 },
    { tt: 32, fr: 110601, to: 111800, v: 39, r: 38 },
    { tt: 32, fr: 111801, to: 117200, v: 39, r: 39 },
    { tt: 32, fr: 117201, to: 117800, v: 39, r: 39 },
    { tt: 32, fr: 117801, to: 119600, v: 39, r: 39 },
    { tt: 32, fr: 119601, to: 120200, v: 40, r: 39 },
    { tt: 32, fr: 120201, to: 120800, v: 40, r: 39 },
    { tt: 32, fr: 120801, to: 125400, v: 40, r: 40 },
    { tt: 32, fr: 125401, to: 130000, v: 40, r: 40 },
    { tt: 32, fr: 130001, to: 131200, v: 41, r: 40 },
    { tt: 32, fr: 131201, to: 131600, v: 41, r: 41 },
    { tt: 32, fr: 131601, to: 132800, v: 41, r: 41 },
    { tt: 32, fr: 132801, to: 134600, v: 41, r: 41 },
    { tt: 32, fr: 134601, to: 142200, v: 41, r: 41 },
    { tt: 32, fr: 142201, to: 143800, v: 42, r: 41 },
    { tt: 32, fr: 143801, to: 145400, v: 42, r: 42 },
    { tt: 32, fr: 145401, to: 148400, v: 42, r: 42 },
    { tt: 32, fr: 148401, to: 149200, v: 42, r: 42 },
    { tt: 32, fr: 149201, to: 157200, v: 42, r: 42 },
    { tt: 32, fr: 157201, to: 158000, v: 43, r: 42 },
    { tt: 32, fr: 158001, to: 158800, v: 43, r: 42 },
    { tt: 32, fr: 158801, to: 168000, v: 43, r: 43 },
    { tt: 32, fr: 168001, to: 172200, v: 43, r: 43 },
    { tt: 32, fr: 172201, to: 173200, v: 43, r: 43 },
    { tt: 32, fr: 173201, to: 175800, v: 43, r: 43 },
    { tt: 32, fr: 175801, to: 177600, v: 44, r: 43 },
    { tt: 32, fr: 177601, to: 191400, v: 44, r: 44 },
    { tt: 32, fr: 191401, to: 193800, v: 44, r: 44 },
    { tt: 32, fr: 193801, to: 199200, v: 44, r: 44 },
    { tt: 32, fr: 199201, to: 201200, v: 45, r: 44 },
    { tt: 32, fr: 201201, to: 203600, v: 45, r: 45 },
    { tt: 32, fr: 203601, to: 213800, v: 45, r: 45 },
    { tt: 32, fr: 213801, to: 228600, v: 45, r: 45 },
    { tt: 32, fr: 228601, to: 230000, v: 45, r: 45 },
    { tt: 32, fr: 230001, to: 232200, v: 46, r: 45 },
    { tt: 32, fr: 232201, to: 242400, v: 46, r: 46 },
    { tt: 32, fr: 242401, to: 248800, v: 46, r: 46 },
    { tt: 32, fr: 248801, to: 271800, v: 46, r: 46 },
    { tt: 32, fr: 271801, to: 274600, v: 47, r: 46 },
    { tt: 32, fr: 274601, to: 279000, v: 47, r: 47 },
    { tt: 32, fr: 279001, to: 279800, v: 47, r: 47 },
    { tt: 32, fr: 279801, to: 320000, v: 47, r: 47 },
    { tt: 32, fr: 320001, to: 330600, v: 47, r: 47 },
    { tt: 32, fr: 330601, to: 332200, v: 47, r: 47 },
    { tt: 32, fr: 332201, to: 335600, v: 48, r: 47 },
    { tt: 32, fr: 335601, to: 357400, v: 48, r: 48 },
    { tt: 32, fr: 357401, to: 404000, v: 48, r: 48 },
    { tt: 32, fr: 404001, to: 427000, v: 48, r: 48 },
    { tt: 32, fr: 427001, to: 431400, v: 49, r: 48 },
    { tt: 32, fr: 431401, to: 448000, v: 49, r: 49 },
    { tt: 32, fr: 448001, to: 497600, v: 49, r: 49 },
    { tt: 32, fr: 497601, to: 519600, v: 49, r: 49 },
    { tt: 32, fr: 519601, to: 598000, v: 49, r: 49 },
    { tt: 32, fr: 598001, to: 604000, v: 50, r: 49 },
    { tt: 32, fr: 604001, to: 727400, v: 50, r: 50 },
    { tt: 32, fr: 727401, to: 746600, v: 50, r: 50 },
    { tt: 32, fr: 746601, to: 818200, v: 50, r: 50 },
    { tt: 32, fr: 818201, to: 996600, v: 50, r: 50 },
    { tt: 32, fr: 996601, to: 1006800, v: 51, r: 50 },
    { tt: 32, fr: 1006801, to: 1212400, v: 51, r: 51 },
    { tt: 32, fr: 1212401, to: Number.MAX_SAFE_INTEGER, v: 51, r: 51 },
];
