import { type IRootState, ensure } from "./root-reducer";
import Config from "../config";
import { DEFAULT_CAR_LOAN_INTEREST_RATE, DEFAULT_PRIVATE_LOAN_INTEREST_RATE } from "../defaults";

export function setStudentLoansFn(state: IRootState, action) {
    const newState = ensure("loans", state);
    newState.userEditingData.loans.studentLoans = Array.from({ length: action.payload }, (_, index) => {
        return newState.userEditingData.loans.studentLoans && index < newState.userEditingData.loans.studentLoans.length
            ? Object.assign({}, newState.userEditingData.loans.studentLoans[index])
            : {
                  amount: 100000,
                  amortization: 900,
                  interest: 0.0053, // Student loan interest latest 10 year average
              };
    });
    return newState;
}

const getPrivateLoanInterest = () => {
    const clientInterestRate: number = Config.get("privateLoanInterestRate");
    return typeof clientInterestRate === "number" ? clientInterestRate : DEFAULT_PRIVATE_LOAN_INTEREST_RATE;
};

export function setPrivateLoansFn(state: IRootState, action) {
    const newState = ensure("loans", state);
    newState.userEditingData.loans.privateLoans = Array.from({ length: action.payload }, (_, index) => {
        return newState.userEditingData.loans.privateLoans && index < newState.userEditingData.loans.privateLoans.length
            ? Object.assign({}, newState.userEditingData.loans.privateLoans[index])
            : {
                  amount: 100000,
                  amortization: 1000,
                  interest: getPrivateLoanInterest(),
              };
    });
    return newState;
}

const getCarLoanInterest = () => {
    const clientInterestRate: number = Config.get("carLoanInterestRate");
    return typeof clientInterestRate === "number" ? clientInterestRate : DEFAULT_CAR_LOAN_INTEREST_RATE;
};

export function setCarLoansFn(state: IRootState, action) {
    const newState = ensure("loans", state);
    newState.userEditingData.loans.carLoans = Array.from({ length: action.payload }, (_, index) => {
        return newState.userEditingData.loans.carLoans && index < newState.userEditingData.loans.carLoans.length
            ? Object.assign({}, newState.userEditingData.loans.carLoans[index])
            : {
                  amount: 100000,
                  amortization: 1000,
                  interest: getCarLoanInterest(),
              };
    });
    return newState;
}

export function setLoanAmountFn(loanType: string) {
    return (state, action) => {
        const newState = ensure("loans", state);
        newState.userEditingData.loans[loanType][action.payload.index].amount = action.payload.value;
        return newState;
    };
}

export function setLoanAmortizationFn(loanType: string) {
    return (state, action) => {
        const newState = ensure("loans", state);
        newState.userEditingData.loans[loanType][action.payload.index].amortization = action.payload.value;
        return newState;
    };
}

export function setLoanInterestFn(loanType: string) {
    return (state, action) => {
        const newState = ensure("loans", state);
        newState.userEditingData.loans[loanType][action.payload.index].interest = action.payload.value;
        return newState;
    };
}
